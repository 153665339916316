import React from 'react';
import { styled } from '@mui/material/styles';
import { Box as MuiBox } from '@mui/material';

export const Box = styled(
  ({ minWidth, maxWidth, minHeight, maxHeight, ...props }) => (
    <MuiBox {...props} />
  )
)`
  width: ${({ width }) => width || '100%'};
  min-width: ${({ minWidth }) => minWidth};
  max-width: ${({ maxWidth }) => maxWidth};
  height: ${({ height }) => height || '100%'};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
`;
