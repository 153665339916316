import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Image } from 'common/components/layouts';

export const MenuIcon = styled(Menu)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  marginRight: '2vw',
}));

export const LoginButton = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.background.main,
  '&.MuiButton-contained': {
    color: theme.palette.primary.main,
    '&:not(.Mui-disabled)': {
      backgroundColor: theme.palette.background.main,
      ':hover': {
        backgroundColor: theme.palette.background.main,
        boxShadow: 'none',
      },
    },
  },
}));

const Div = styled('div')(({ theme }) => ({
  width: '20rem',
  maxWidth: '85vw',
  height: theme.constants.MuiAppBar.height,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 2rem 0 3rem',
  backgroundColor: 'white',
  borderRadius: '0 0 1.5rem 0',
}));

export const ImageContainer = ({ src, to }) => (
  <Div>
    <Image src={src} />
  </Div>
);
