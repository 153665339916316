import React from 'react';
import {
  List as MuiList,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import { CircleOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';

const List = ({ listItems, ...props }) => {
  return (
    <MuiList {...props}>
      {listItems.map((item, idx) => (
        <ListItem
          key={idx}
          sx={{
            paddingLeft: 0,
            paddingRight: 0,
            alignItems: 'flex-start'
          }}
          dense
        >
          <ListItemIcon sx={{ marginTop: '0.6rem', minWidth: '1.25rem' }}>
            <CircleOutlined color="primary" fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{item}</Typography>}
          />
        </ListItem>
      ))}
    </MuiList>
  );
};

List.defaultProps = {};
List.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default List;
