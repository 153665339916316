import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, FlexBox, ContentBox, Footer } from 'common/components/layouts';

const FullPageLayout = ({ children, history, isMobile }) => {
  return (
    <ContentBox overflow="hidden">
      <AppBar history={history} isMobile={isMobile} />
      <FlexBox
        flexDirection="column"
        flexWrap="noWrap"
        height="100%"
        width="100%"
      >
        {children}
      </FlexBox>
      <Footer isMobile={isMobile} />
    </ContentBox>
  );
};

FullPageLayout.defaultProps = {
  isMobile: false,
};
FullPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
};

export default FullPageLayout;
