import React, { useState } from 'react';
import { AppBar as MuiAppBar, Collapse } from '@mui/material';
// import PropTypes from 'prop-types';
import { FlexBox } from 'common/components/layouts';
import Logo from 'common/assets/logos/myhealthplan-logo.svg';
import {
  ImageContainer as StyledImageContainer,
  MenuIcon as StyledMenuIcon,
  LoginButton as StyledLoginButton,
} from './styles';

const AppBar = ({ isMobile }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <MuiAppBar position="absolute">
      <FlexBox
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
        height="100%"
        width="100%"
        justifyContent="space-between"
      >
        <StyledImageContainer src={Logo} />
        {!isMobile && (
          <FlexBox
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            onMouseEnter={handleExpandClick}
            onMouseLeave={handleExpandClick}
          >
            <Collapse in={expanded} orientation="horizontal">
              <StyledLoginButton
                href="https://app.myhealthplan.com.au/login"
                variant="contained"
              >
                provider login
              </StyledLoginButton>
            </Collapse>

            <StyledMenuIcon />
          </FlexBox>
        )}
      </FlexBox>
    </MuiAppBar>
  );
};

AppBar.defaultProps = {};
AppBar.propTypes = {};

export default AppBar;
