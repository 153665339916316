import React, { useState } from 'react';
import {
  Stack,
  Card,
  CardActionArea,
  CardContent,
  Slide,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import GlobalTheme from 'common/styles/global';

const HoverCard = ({ title, children, color, expanded, isMobile, ...props }) => {
  const [expand, setExpand] = useState(expanded);

  const handleMouseEnter = () => {
      !isMobile && setExpand(true);
    },
    handleMouseLeave = () => {
      !isMobile && setExpand(false);
    },
    handleClick = () => {
      setExpand(!expand);
    };

  return (
    <Card {...props}>
      <CardActionArea
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          position: 'relative',
          backgroundColor: 'white',
        }}
      >
        <Slide
          direction="up"
          in={!expand}
          timeout={800}
          sx={{
            position: 'absolute',
            top: 0,
            width: '100%',
            backgroundImage: `linear-gradient(to bottom,
              ${GlobalTheme.palette.secondary.main},
              ${GlobalTheme.palette.primary.main}
            )`,
            borderRadius: '0.5rem',
            zIndex: 1,
          }}
          unmountOnExit
        >
          <CardContent>
            <Typography
              variant="h3"
              color={GlobalTheme.palette[color].contrastText}
            >
              {title}
            </Typography>
          </CardContent>
        </Slide>
        <CardContent>
          <Stack
            justifyContent="space-between"
            sx={{
              height: '100%',
              minHeight: '20rem',
            }}
          >
            {children}
            <Typography
              variant="h4"
              color={GlobalTheme.palette[color].main}
              align="right"
              mt={4}
              mb={0}
            >
              {title}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

HoverCard.defaultProps = {
  expanded: false,
  isMobile: false,
};
HoverCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'error',
    'warning',
    'success',
  ]),
  expanded: PropTypes.bool,
  isMobile: PropTypes.bool,
};

export default HoverCard;
