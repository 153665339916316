import { styled } from '@mui/material/styles';
import { Img } from 'react-image';
import heroImage from 'common/assets/graphics/mhp_journey.png';
import { Button as MuiButton } from 'common/components/buttons';
import { Typography as MuiTypography } from '@mui/material';

export const HeroImage = styled(({ ...props }) => (
  <Img src={heroImage} {...props} />
))`
  max-height: 70vh;
  border-radius: 1rem;
`;

export const Button = styled(MuiButton)(({ theme }) => ({
  width: '100%',
  marginTop: '1.2rem'
}));

export const Typography = styled(MuiTypography)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.primary.contrastText,
  padding: '1rem',
  borderRadius: '1rem',
  width:'fit-content',
  margin: '2rem 0',
}));