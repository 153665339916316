import React from 'react';
import { compose } from 'redux';
import { FullPageLayout } from 'common/components/layouts';
// import { withRouterProps, withPageLoader } from 'common/HOCs';
import { useScrollTop } from 'common/hooks';
import withIsMobile from './withIsMobile';

// handles generic page styling
function withFullPageLayout(OriginalComponent) {
  const Component = ({ ...props }) => {
    useScrollTop({ history: props.history });

    return (
      <FullPageLayout
        isAuthenticated={props.isAuthenticated}
        maxWidth={props.maxWidth}
        history={props.history}
        isMobile={props.isMobile}
      >
        <OriginalComponent {...props} />
      </FullPageLayout>
    );
  };

  // Component.defaultProps = {};
  // Component.propTypes = {};
  // Component.displayName = `withFullPageLayout(${getComponentDisplayName(
  //   OriginalComponent
  // )})`;

  const Container = compose(withIsMobile)(Component);

  return Container;
}

export default withFullPageLayout;
