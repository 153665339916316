import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import GlobalTheme from 'common/styles/global';

const Footer = ({ isMobile }) => {
  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      alignItems="center"
      justifyContent="center"
      spacing={2}
      px={1}
      py={2}
      sx={{
        backgroundImage: `linear-gradient(to bottom right,
          ${GlobalTheme.palette.primary.main},
          ${GlobalTheme.palette.secondary.main})`,
      }}
      // style={{position: 'relative'}}
    >
      <Stack px={1}>
        <Typography align="center" color="primary.contrastText">
          (08) 8323 9266
        </Typography>
        <Typography align="center" color="primary.contrastText">
          reception@mvfp.com.au
        </Typography>
      </Stack>
      <Stack px={1}>
        <Typography align="center" color="primary.contrastText">
          McLaren Vale Family Practice
        </Typography>
        <Typography align="center" color="primary.contrastText">
          137 Main Road, McLaren Vale, 5172, SA
        </Typography>
      </Stack>
      {/* <Typography variant="body2" align="right" color="primary.contrastText" style={{position: 'absolute', bottom: 0, right: '1vw'}}>
          v{process.env.REACT_APP_VERSION}
      </Typography> */}
    </Stack>
  );
};

Footer.defaultProps = {};
Footer.propTypes = {
  isMobile: PropTypes.bool.isRequired,
};

export default Footer;
