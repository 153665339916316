import GreycliffCFRegularWoff2 from 'common/assets/fonts/greycliffcf-regular-webfont.woff2';
import GreycliffCFRegularWoff from 'common/assets/fonts/greycliffcf-regular-webfont.woff';
import GreycliffCFBoldWoff2 from 'common/assets/fonts/greycliffcf-bold-webfont.woff2';
import GreycliffCFBoldWoff from 'common/assets/fonts/greycliffcf-bold-webfont.woff';
import YanoneKaffeesatzWoff from 'common/assets/fonts/YanoneKaffeesatz-Light.woff2';

export const greycliffCF = [
  {
    fontFamily: 'GreycliffCF',
    src: `url(${GreycliffCFRegularWoff2}) format('woff2'), url(${GreycliffCFRegularWoff}) format('woff')`,
    fontStyle: 'normal',
    fontWeight: 400,
  },
  {
    fontFamily: 'GreycliffCF',
    src: `url(${GreycliffCFBoldWoff2}) format('woff2'), url(${GreycliffCFBoldWoff}) format('woff')`,
    fontStyle: 'normal',
    fontWeight: 700,
  },
];

export const yanoneKaffeesatz = {
  fontFamily: 'Yanone Kaffeesatz',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
		local('Yanone Kaffeesatz'),
		url(${YanoneKaffeesatzWoff}) format('woff2')
	  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
