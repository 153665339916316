import React from 'react';

function withIsMobile(OriginalComponent) {
  const Component = ({ ...props }) => {
    // Detects if device is mobile
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    return <OriginalComponent isMobile={isMobile} {...props} />;
  };

  return Component;
}

export default withIsMobile;
