import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'common/utils/ErrorBoundary';
import paths from 'routing/paths';
import LandingPage from 'pages/landing';
// import ClinicalDataPage from 'pages/static/clinicalData';
// import CyclesOfCarePage from 'pages/static/cyclesOfCare';
// import HealthActionsPage from 'pages/static/healthActions';
// import HealthGoalsPage from 'pages/static/healthGoals';
// import HealthServicesPage from 'pages/static/healthServices';
// import HealthTeamPage from 'pages/static/healthTeam';
// import ProgressPage from 'pages/static/progress';
// import CompletePage from 'pages/static/complete';
// import TermsPage from 'pages/static/termsAndConditions';
// import PrivacyPage from 'pages/static/privacyPolicy';
import Http404Page from 'pages/http404';

const Router = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <Switch>
        <Route exact path={paths.LANDING} component={LandingPage} />
        {/* <Route exact path={paths.CLINICAL_DATA} component={ClinicalDataPage} />
				<Route exact path={paths.CYCLES_OF_CARE} component={CyclesOfCarePage} />
				<Route
					exact
					path={paths.HEALTH_ACTIONS}
					component={HealthActionsPage}
				/>
				<Route exact path={paths.HEALTH_GOALS} component={HealthGoalsPage} />
				<Route
					exact
					path={paths.HEALTH_SERVICES}
					component={HealthServicesPage}
				/>
				<Route exact path={paths.HEALTH_TEAM} component={HealthTeamPage} />
				<Route exact path={paths.PROGRESS} component={ProgressPage} />
				<Route exact path={paths.COMPLETE} component={CompletePage} />
				<Route exact path={paths.TERMS} component={TermsPage} />
				<Route exact path={paths.PRIVACY} component={PrivacyPage} /> */}
        <Route component={Http404Page} />
      </Switch>
    </ErrorBoundary>
  </BrowserRouter>
);

export default Router;
