export default {
  LANDING: '/',
  HEALTH_GOALS: '/health-goals',
  CLINICAL_DATA: '/clinical-data',
  CYCLES_OF_CARE: '/cycles-of-care',
  HEALTH_ACTIONS: '/health-actions',
  HEALTH_SERVICES: '/health-services',
  HEALTH_TEAM: '/health-team',
  PROGRESS: '/progress',
  COMPLETE: '/complete',
  TERMS: '/terms-and-conditions',
  PRIVACY: '/privacy-policy',
};
