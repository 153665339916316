import { useEffect } from 'react';
import history from 'routing/history';

export default function () {
  useEffect(() => {
    const unlisten = history.listen(() => window.scrollTo({ top: 0 }));

    return () => unlisten();
  }, []);

  return null;
}
