import { createTheme, alpha, lighten } from '@mui/material/styles';
import { greycliffCF } from './fonts';

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);

let GlobalTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#49bcdb',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#449ff2',
      contrastText: '#ffffff',
    },
    error: {
      main: '#db4949',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ffa64d',
      contrastText: '#ffffff',
    },
    success: {
      main: '#20d489',
      contrastText: '#ffffff',
    },
    text: {
      primary: '#5e6162',
      secondary: '#a9abad',
      disabled: '#c9c9c9',
    },
    background: {
      main: '#f2f2f2', //off white
      white: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  constants: {
    MuiAppBar: {
      height: '3.6rem',
    },
  },
  isMobile: isMobile,
});


GlobalTheme = createTheme(GlobalTheme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [...greycliffCF],
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          height: GlobalTheme.constants.MuiAppBar.height,
          boxShadow: 'none', //'rgba(0, 0, 0, 0.06) 0 0.5rem 1.5rem 0',
        },
        colorPrimary: {
          backgroundColor: 'transparent', // GlobalTheme.palette.primary.contrastText,
          color: GlobalTheme.palette.text.primary,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: GlobalTheme.zIndex.drawer + 1,
          backgroundColor: alpha(GlobalTheme.palette.text.secondary, 0.25),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          fontFamily: 'GreycliffCF, sans-serif',
          fontSize: '0.55rem',
          fontWeight: 700,
          lineHeight: 1.15,
          width: '8.5rem',
          padding: '0.6rem 0',
          margin: '0.16rem',
          borderRadius: '2rem',
          backgroundColor: 'transparent',
          '&:hover': {
            backgroundColor: 'transparent',
          },
        },
        contained: {
          border: '2px solid !important',
          borderColor: 'transparent !important',
          boxShadow: 'rgba(73, 188, 219, 0.21) 0 0.15rem 0.5rem 0',
        },
        outlined: {
          border: '2px solid !important',
          borderColor: 'inherit !important',
          padding: null,
        },
        text: {
          padding: 'calc(0.6rem + 2px) 2px',
        },
        sizeSmall: {
          width: 'auto',
          minWidth: '2rem',
          padding: 'calc(0.2rem - 2px) calc(0.4rem - 2px)',
          margin: '0.08rem',
        },
        outlinedSizeSmall: {
          fontSize: null,
        },
        containedSizeSmall: {
          fontSize: null,
        },
        textSizeSmall: {
          padding: '0.2rem 0.6rem',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          marginBottom: '1rem',
          '& .MuiCard-root .MuiCardContent-root': {
            padding: '1rem',
          },
        },
      },
    },
    MuiCardActionArea: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          height: '100%',
          padding: '1.5rem',
          '&:last-child': {
            paddingBottom: null,
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: isMobile ? '1.2rem' : '3.6rem 1.2rem',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          '& > *': {
            zIndex: GlobalTheme.zIndex.modal,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '& .MuiCard-root .MuiCardContent-root': {
            padding: '1rem',
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 700,
          lineHeight: 1.2,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          display: 'flex',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'GreycliffCF, sans-serif',
          fontWeight: 400,
          color: GlobalTheme.palette.primary.main,
          '&.Mui-disabled': {
            color: GlobalTheme.palette.text.secondary,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'GreycliffCF, sans-serif',
          fontSize: '0.6rem',
          fontWeight: 700,
          lineHeight: 1.5,
          marginTop: 0,
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        container: {
          marginBottom: 'calc(1rem - 12px) !important',
        },
        item: {
          '& .MuiCard-root': {
            margin: 0,
            height: '100%',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: `1px solid ${lighten(
              GlobalTheme.palette.text.disabled,
              0.6
            )} !important`,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: GlobalTheme.palette.text.primary,
        },
        input: {
          color: GlobalTheme.palette.text.primary,
          fontFamily: 'GreycliffCF, sans-serif',
          fontWeight: 700,
          lineHeight: 1,
          '&.Mui-disabled': {
            color: GlobalTheme.palette.text.secondary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          fontSize: '0.85rem',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: 'rgba(0, 0, 0, 0.1) 0 0 1.25rem 0',
        },
        list: {
          padding: 0,
          textAlign: 'center',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          height: '2.5rem',
          '&.Mui-focusVisible': {
            backgroundColor: 'white',
          },
          '&:hover': {
            color: GlobalTheme.palette.primary.contrastText,
            backgroundColor: GlobalTheme.palette.primary.main,
          },
          '& p': {
            fontFamily: 'GreycliffCF, sans-serif',
            fontSize: '0.75rem',
            fontWeight: 700,
            width: '100%',
            textAlign: 'center',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          color: GlobalTheme.palette.text.primary,
          overflowX: 'hidden',
        },
        outlined: {
          border: `2px solid ${GlobalTheme.palette.primary.main}`,
        },
        rounded: {
          borderRadius: '0.5rem',
        },
        elevation1: {
          boxShadow: 'rgba(0, 0, 0, 0.05) 0 0 1.25rem 0',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        fontSizeSmall: {
          fontSize: '0.75rem',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          marginTop: '-1rem',
          borderSpacing: '0 1rem',
          borderCollapse: 'separate',
          tableLayout: 'fixed',
          '&.dense': {
            borderSpacing: '0 0.5rem',
            '& th, td': {
              padding: '0 0.5rem !important',
            },
            '& th:first-of-type:not(.MuiTableCell-paddingCheckbox), td:first-of-type:not(.MuiTableCell-paddingCheckbox)':
              {
                paddingLeft: '1rem !important',
              },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          borderBottom: 'unset',
          fontFamily: 'GreycliffCF, sans-serif',
          fontWeight: 700,
        },
        head: {
          color: GlobalTheme.palette.text.disabled,
          fontSize: '0.7rem',
          lineHeight: 1.2,
          padding: '0 1rem',
        },
        body: {
          color: GlobalTheme.palette.text.primary,
          fontSize: '0.8rem',
          padding: '0 1rem',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: 'GreycliffCF, sans-serif',
          fontSize: '0.75rem',
          fontWeight: 400,
          lineHeight: 1.5,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'GreycliffCF, sans-serif',
          fontWeight: 400,
          color: GlobalTheme.palette.text.primary,
        },
        h1: {
          fontSize: 'min(16vw, 12vh)',
          marginBottom: '1rem',
        },
        h2: {
          fontSize: 'min(10vw, 6vh)',
          marginBottom: '0.75rem',
        },
        h3: {
          fontSize: 'min(8vw, 4vh)',
          marginBottom: '0.5rem',
        },
        h4: {
          fontSize: 'min(6vw, 3vh)',
          marginBottom: '0.25rem',
        },
        subtitle1: {
          fontSize: '1vh',
        },
        body1: {
          lineHeight: 1.5,
        },
        body2: {
          lineHeight: 1,
        },
      },
    },
  },
});

export default GlobalTheme;
