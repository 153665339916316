import React from 'react';
import ReactDOM from 'react-dom';
import Router from './routing/components/Router';
import ThemeProvider from 'common/styles/ThemeProvider';

ReactDOM.render(
  <ThemeProvider>
    <Router />
  </ThemeProvider>,
  document.getElementById('root')
);
