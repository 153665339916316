import React from 'react';
import { Box, Container, Stack, Typography } from '@mui/material';
import { HoverCard, List } from 'common/components/layouts';
import GlobalTheme from 'common/styles/global';
import { HeroImage as StyledHeroImage, Button as StyledButton, Typography as StyledTypography } from './styles';

const LandingPage = ({ isMobile }) => {
  return (
    <>
      <Box
        width="100%"
        pt={GlobalTheme.constants.MuiAppBar.height}
        sx={{
          backgroundImage: `linear-gradient(to bottom right,
						${GlobalTheme.palette.secondary.main},
						${GlobalTheme.palette.primary.main}
					)`,
        }}
      >
        <Container maxWidth="xl" paddingbottom="1.6rem !important">
          <Stack direction="row" alignItems="center" spacing={4}>
            <Box>
                <Typography variant="h1" color="primary.contrastText">
                  Welcome to your healthier future
                </Typography>
                <Typography variant="body1" color="primary.contrastText">
                  myhealthplan is a care planning system that documents your
                  health goals and provides a framework to achieve these goals.
                  This framework consists of your health care (what others do for
                  you) and your self care (what you can do for yourself).
                </Typography>
                <Stack alignItems="center">
                  {isMobile ?
                    <StyledButton label="login" color="warning" href="https://patient.app.myhealthplan.com.au/login"/>
                    :
                    <StyledTypography variant="body1">
                      Visit this site on your mobile to download our app!
                    </StyledTypography>
                  }
                </Stack>
            </Box>
            {!isMobile && <StyledHeroImage />}
          </Stack>
        </Container>
      </Box>
      <Box
        width="100%"
        sx={{
          backgroundColor: GlobalTheme.palette.background.main,
        }}
      >
        <Container>
          <Typography variant="h2">what we provide</Typography>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={isMobile ? 0 : 2}
          >
            <HoverCard
              title="your healthplan"
              color="primary"
              isMobile={isMobile}
              sx={!isMobile && { flexBasis: '33%', marginBottom: 0 }}
            >
              <List
                listItems={[
                  'Flexible 2 year period',
                  'Integrates with your routine medical care',
                  'Requires minimal additional attendances',
                ]}
              />
            </HoverCard>
            <HoverCard
              title="customizable preventive health modules"
              color="primary"
              isMobile={isMobile}
              sx={!isMobile && { flexBasis: '33%', marginBottom: 0 }}
            >
              <List
                listItems={[
                  'Cardiovascular cycle of care',
                  'Respiratory cycle of care',
                  'Cancer cycle of care',
                  'Metabolic (body chemistry) cycle of care',
                  'Longevity cycle of care',
                  'Immunisation cycle of care',
                  'Neurological cycle of care',
                ]}
              />
            </HoverCard>
            <HoverCard
              title="additional benefits and medicare compliance"
              color="primary"
              isMobile={isMobile}
              sx={!isMobile && { flexBasis: '33%', marginBottom: 0 }}
            >
              <List
                listItems={[
                  'Medicare compliant',
                  '5 free allied health visits per calendar year*',
                  'Integrates with MyHealthRecord',
                  'Facilitates a "meds check" by your pharmacist',
                  'Qualifies you for a formal Domiciliary Medication Management Review*',
                ]}
              />
              <Typography variant="body2" color="textSecondary" mt={1}>
                * Medicare conditions apply
              </Typography>
            </HoverCard>
          </Stack>
        </Container>
      </Box>

      <Box width="100%" pb={2}>
        <Container>
          <Typography variant="h2" align="center">
            contact us now to start your journey
          </Typography>
          <Typography variant="body1" align="center" color="textSecondary">
            Let's get started! Contact McLaren Vale Family Practice on 8323 9266
            and ask to book your myhealthplan initial consult today!
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default LandingPage;
